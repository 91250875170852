import React from 'react';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SearchIcon from '@material-ui/icons/Search';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Badge } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 275,
            backgroundColor: '#F2F2F2'
        },
        small: {
            width: theme.spacing(2.8),
            height: theme.spacing(2.8),
        },
    }),
);

withStyles((_theme: Theme) =>
    createStyles({
        badge: {
            right: -25,
            top: 14,
            padding: '0 4px',
        },
    }),
)(Badge);

type Props = {
    numberOfUnreadMessages: number
    showNotifications: () => void
    showLatestMessages: () => void
    showSearch: () => void
}

export const ActionsComponent = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem button onClick={props.showSearch}>
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Haku" />
                </ListItem>
                <ListItem button onClick={props.showNotifications}>
                    <ListItemIcon>
                        <AnnouncementIcon />
                    </ListItemIcon>
                    <ListItemText primary="Ilmoitukset" />
                </ListItem>

                <ListItem button onClick={props.showLatestMessages}>
                    <ListItemIcon>
                        <AccessTimeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Uusimmat viestit" />
                </ListItem>
            </List>
            <Divider />
        </div>
    )
}