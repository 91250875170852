
const prod = {
    loginApi: "https://kota.vr.fi/api/client",
    clientApi: "https://kota.vr.fi/api/client",    
    clientWebSocketApi: "wss://pp0dwgji07.execute-api.eu-west-1.amazonaws.com/prod",
    loginPageUrl: "https://kota.vr.fi/api/client/login?client=new-kota-dispatcher"
}

const test = {
    loginApi: "https://dev-kota.vr.fi/api/client",
    clientApi: "https://dev-kota.vr.fi/api/client",    
    clientWebSocketApi: "wss://fiy7nesg73.execute-api.eu-west-1.amazonaws.com/test",
    loginPageUrl: "https://dev-kota.vr.fi/api/client/login?client=new-kota-dispatcher"
}

const dev = {
    loginApi: "https://dev-kota.vr.fi/api/client",
    clientApi: "https://dev-kota.vr.fi/api/client",    
    clientWebSocketApi: "wss://fiy7nesg73.execute-api.eu-west-1.amazonaws.com/test",
    loginPageUrl: "https://dev-kota.vr.fi/api/client/login?client=local-kota-dispatcher"
}

let config = dev
switch (process.env.REACT_APP_STAGE) {
    case 'prod': {
        config = prod;
        break;
    }
    case 'test': {
        config = test;
        break;
    }
    case 'dev': {
        config = dev;
        break;
    }
    default: {
        config = dev;
        break;
    }
}

const defaultConfig = { ...config }
export default defaultConfig