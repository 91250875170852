import axios from 'axios'
import config from '../config'
import { PersistentStorage } from '../data/PersistentStorage'
import { GroupChannelDTO, GroupChannelMessageDTO, GeneralNotificationDTO, PersonalMessageDTO, UserData } from './DTOs'
import { ConductorLocation } from '../domain/entities/Location'
import { EmergencyAlarmTask } from '../domain/entities/DispatcherTask'

export type AuthResponse = {
    user: { id: string, firstName: string, lastName: string, groups: string[] }
    authorization: string,
    expires: number
}

type EmergencyAlarmDTO = {
    by: string
    startTime: number
    status: 'open' | 'closed'
    endTime?: number
    dispatcherResolvedTime?: number
    triggeredAtLocation: ConductorLocation
}

export class ClientApiImpl {

    constructor(readonly persistentStorage: PersistentStorage) { }

    async getChatChannels(from: number, to: number): Promise<GroupChannelDTO[]> {
        try {
            const response = await axios.get<GroupChannelDTO[]>(config.clientApi + '/group-channels?from=' + from + "&to=" + to, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
            if (response.status === 200) {
                return response.data
            }
            else {
                return [] // TODO: handle these!!!
            }
        }
        catch (error) {
            return []
        }
    }

    async getMessages(from: number, to: number): Promise<GroupChannelMessageDTO[]> {
        try {
            const response = await axios.get<GroupChannelMessageDTO[]>(config.clientApi + '/group-channels/messages?from=' + from + "&to=" + to, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
            if (response.status === 200) {
                return response.data
            } else {
                return [] // TODO: handle these!!!
            }
        }
        catch (error) {
            return []
        }
    }

    // TODO: this transformation to dispatcher task is quite hacky. Temporary quick fix. Implement alarm handling properly.
    async getAlarms(): Promise<EmergencyAlarmTask[] | undefined> {
        try {
            const response = await axios.get<EmergencyAlarmDTO[]>(config.clientApi + '/emergency-alarms', {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
            if (response.status === 200) {
                return response.data.map((it) => {
                    return {
                        type: 'emergency-alarm',
                        id: it.by + '-' + it.startTime,
                        status: it.status,                        
                        alarmStarted: it.startTime,
                        alarmEnded: it.endTime ?? it.dispatcherResolvedTime, // TODO: update the emergency-alarm type
                        by: it.by,
                        triggeredAtLocation: it.triggeredAtLocation
                    } as EmergencyAlarmTask
                })
            }
            else {
                return undefined
            }
        }
        catch (error) {
            return undefined
        }
    }

    async resolveAlert(alarmBy: string, alarmStartTime: number): Promise<{success: boolean}> {
        try {
            const response = await axios.post(config.clientApi + '/emergency-alarms/resolve', { alarmBy, alarmStartTime }, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })

            if (response.status === 200) {
                return { success: true}
            } else {
                return { success: false}
            }
        }
        catch (error) {
            return { success: false }
        }
    }

    sendMessageToChannel(channelId: string, message: string): Promise<void> {
        return axios
            .post(config.clientApi + '/group-channels/' + channelId + '/messages', { message: message, as: 'dispatcher' }, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
    }

    sendGeneralNotification(message: string): Promise<void> {
        return axios
            .post(config.clientApi + '/notifications', { message }, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
    }

    sendPersonalMessage(userId: string, message: string): Promise<void> {
        return axios
            .post(config.clientApi + '/users/' + userId + '/personal-messages', { message, as: 'dispatcher' }, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
    }

    async getGeneralNotifications(): Promise<GeneralNotificationDTO[]> {
        try {
            const response = await axios.get<GeneralNotificationDTO[]>(config.clientApi + '/notifications', {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
            if (response.status === 200) {
                return response.data
            }
            else {
                return [] // TODO: handle these!!!
            }
        }
        catch (error) {
            return []
        }
    }

    async getAllPersonalMessages(from: number, to: number): Promise<PersonalMessageDTO[]> {
        try {
            const response = await axios.get<PersonalMessageDTO[]>(config.clientApi + '/users/personal-messages?from=' + from + "&to=" + to, {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
            if (response.status === 200) {
                return response.data
            } else {
                return [] // TODO: handle these!!!
            }
        }
        catch (error) {
            return []
        }
    }

    async createCustomChannel(name: string, participants: string[]): Promise<{ id: string }> {
        const response = await axios.post<{ id: string }>(config.clientApi + '/group-channels', { channelName: name, participants: participants }, {
            headers: {
                Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
            }
        })

        if (response.status === 200) {
            return response.data
        } else {
            throw Error("failed")
        }
    }


    async login(code: string): Promise<AuthResponse> {
        const response = await axios.get<AuthResponse>(config.loginApi + '/login/token?code=' + code)
        if (response.status === 200) {
            return response.data
        } else {
            throw new Error('failed')
        }
    }

    // TODO: entity and DTO should be different
    async getActiveUsers(): Promise<UserData[]> {
        try {
            const response = await axios.get<UserData[]>(config.clientApi + '/active-conductors', {
                headers: {
                    Authorization: 'Bearer ' + this.persistentStorage.getAuthDetails()?.authorization
                }
            })
            if (response.status === 200) {
                return response.data
            } else {
                return [] // TODO: handle these!!!
            }
        }
        catch (error) {
            return []
        }
    }
}